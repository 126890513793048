import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppIds, AppRoutes } from '@app/app-routes';
import { OrganizationStateService } from '@app/states/organization/organization-state.service';
import { Organization } from '@app/states/organization/organization.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFieldsLoaderService } from '@shared/ruv-forms/form-loader.service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { VerificationCode } from './code.model';

@Component({
  selector: 'ruv-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  standalone: false,
})
export class CodeComponent {
  public form = new FormGroup({});
  public model: VerificationCode = {
    code: '',
  };
  public fields$!: Observable<FormlyFieldConfig[]>;
  public organization$!: Observable<Organization | undefined>;

  private isSubmitPending = false;

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private formFieldsLoader: FormFieldsLoaderService,
    private stateService: OrganizationStateService,
  ) {
    this.fields$ = this.formFieldsLoader.loadFormByName('code');
    this.organization$ = this.stateService.organization$;
  }

  public async submit(model: VerificationCode): Promise<any> {
    if (this.isSubmitPending) {
      return;
    }

    if (model.code) {
      const configId = this.route.snapshot.paramMap.get(AppIds.ConfigId);

      this.logger.debug('Verification code found');
      this.router.navigate([configId, AppRoutes.Verification], {
        queryParams: { code: model.code },
      });
    } else {
      this.logger.error('Verification code is missing');
      this.router.navigate([AppRoutes.Error]);
    }
  }
}
